import useSWRImmutable from 'swr';
import { useCallback } from 'react';
import {
  Entries,
  Entry
} from './type'
import { get } from 'libs/fetch';

export const useEntries = (userId: string) => {
  const query = new URLSearchParams();
  query.append('user_id', userId);

  const fetchEntries = useCallback(async () => {
    const response = await get(`api/v1/admin/entries?${query.toString()}`);

    if (response.ok) {
      const body: Entries = await response.json();
      return body.entries;
    }

    const error: Error = new Error('エラーになりました');
    throw error;
  }, [userId]);

  const { data, error } = useSWRImmutable<Entry[], Error>(
    `api/v1/admin/entries?${query.toString()}`,
    fetchEntries
  );

  if (error) return { error, isLoading: false };
  if (!data) return { isLoading: true };
  return { entries: data, isLoading: false };
};
