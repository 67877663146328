import { FC, useState, useEffect } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import Panel from 'components/Panel';
import PanelTitle from 'components/PanelTitle';
import { User } from 'libs/apiClient/admin/users';
import { useFetchPrefectures } from 'hooks/api/prefectures/useFetchPrefectures';
import {
  updatePath,
  RequestData,
  Errors,
  SpecialityClassification
} from 'libs/apiClient/admin/insideSales/users';
import { update } from 'libs/apiClient';
import { useUniversityOptions } from 'hooks/useUniversityOptions';
import { useUniversityFacultyOptions } from 'hooks/useUniversityFacultyOptions';
import { useUniversityDepartmentOptions } from 'hooks/useUniversityDepartmentOptions';
import DateTime from 'components/DateTime';

type FormProps = {
  user: User;
  onClose: () => void;
}

const GENDER_MAP = {
  '男性': 'man',
  '女性': 'woman',
  'その他': 'other',
}

const SPECIALITY_CLASSIFICATION_MAP = {
  '文系': 'arts',
  '理系': 'sciences',
  'その他': 'others'
}

type FormData = {
  gender: string;
  specialityClassification: SpecialityClassification;
  jobHuntingStatus: string;
  isEmailAvailable: boolean;
  isPhoneAvailable: boolean;
  universityId?: string;
  universityFacultyId?: string;
  universityDepartmentId?: string;
  admissionOn: string;
  graduateOn: string;
}

const UserUpdateForm: FC<FormProps> = ({ user, onClose }) => {
  const [formData, setFormData] = useState<FormData>({
    gender: GENDER_MAP[user.gender],
    specialityClassification: SPECIALITY_CLASSIFICATION_MAP[user.specialityClassification] as SpecialityClassification,
    jobHuntingStatus: user.jobHuntingStatus,
    isEmailAvailable: user.isEmailAvailable,
    isPhoneAvailable: user.isPhoneAvailable,
    universityId: user.universitySet?.universityId,
    universityFacultyId: user.universitySet?.universityFacultyId,
    universityDepartmentId: user.universitySet?.universityDepartmentId,
    admissionOn: user.universitySet?.admissionOn,
    graduateOn: user.universitySet?.graduateOn
  })
  const [errors, setErrors] = useState<Errors>({
    gender: [],
    specialityClassification: [],
  });
  const { prefectures } = useFetchPrefectures()
  const [degree, setDegree] = useState<string | undefined>(user.universitySet?.degree)
  const [searchKey, setSearchKey] = useState<string | undefined>(user.universitySet?.searchKey)
  const { reloadUniversityOptions, universityOptions } = useUniversityOptions();
  const { reloadUniversityFacultyOptions, universityFacultyOptions } = useUniversityFacultyOptions();
  const { reloadUniversityDepartmentOptions, universityDepartmentOptions } = useUniversityDepartmentOptions();

  const onChange = (value: FormData[keyof FormData], key: keyof FormData): void => {
    const data = { ...formData, [key]: value }
    setFormData(data);
  }

  const handleSubmit = () => {
    const callback = (_res: unknown) => {
      onClose()
    }

    const errCallback = (err: Errors) => {
      setErrors(err)
    }

    const body: RequestData = {
      firstName: user.firstName,
      lastName: user.lastName,
      firstNameKana: user.firstNameKana,
      lastNameKana: user.lastNameKana,
      email: user.email,
      phoneNumber: user.phoneNumber,
      graduateYear: Number(user.graduateYear),
      graduateMonth: 3,
      prefectureId: user.prefecture.id,
      gender: formData.gender,
      specialityClassification: formData.specialityClassification,
      jobHuntingStatus: formData.jobHuntingStatus,
      isEmailAvailable: formData.isEmailAvailable,
      isPhoneAvailable: formData.isPhoneAvailable,
      universitySet: {
        id: user.universitySet?.id,
        universityId: formData.universityId || '',
        universityFacultyId: formData.universityFacultyId || '',
        universityDepartmentId: formData.universityDepartmentId || '',
        admissionOn: formData.admissionOn,
        graduateOn: formData.graduateOn
      }
    }

    user && user.userId && update(updatePath(user.userId), { user: body }, 'user', callback, errCallback);
  }

  const onChangeKey = (searchKey?: string, degree?: string) => {
    setSearchKey(searchKey)
    setDegree(degree)

    if (searchKey && degree) {
      reloadUniversityOptions(searchKey, degree)
      setFormData({
        ...formData,
        universityId: '',
        universityFacultyId: '',
        universityDepartmentId: ''
      })
    }
  }

  useEffect(() => {
    if (searchKey && degree) {
      reloadUniversityOptions(searchKey, degree)
    }
  }, [])

  useEffect(() => {
    if (formData.universityId) {
      reloadUniversityFacultyOptions(formData.universityId)
    }
  }, [formData.universityId])

  useEffect(() => {
    if (formData.universityFacultyId) {
      reloadUniversityDepartmentOptions(formData.universityFacultyId)
    }
  }, [formData.universityFacultyId])

  return (
    <Panel className='pb-4'>
      <PanelTitle title={`ユーザー編集(ユーザーID: ${user.userId})`} />
      <Form noValidate className='col-6'>
        <Form.Group controlId='graduationYear'>
          <Form.Label>卒年</Form.Label>
          <Form.Control
            type='text'
            name='graduateYear'
            value={`${user.graduateYear}年` || ''}
            readOnly
          >
          </Form.Control>
        </Form.Group>

        <Row>
          <Form.Group as={Col} md="6" controlId='lastName'>
            <Form.Label>姓</Form.Label>
            <Form.Control
              type='text'
              name="lastName"
              value={user.lastName}
              readOnly
            />
          </Form.Group>

          <Form.Group as={Col} md="6" controlId='firstName'>
            <Form.Label>名</Form.Label>
            <Form.Control
              type='text'
              name="firstName"
              value={user.firstName}
              readOnly
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} md="6" controlId='lastNameKana'>
            <Form.Label>セイ</Form.Label>
            <Form.Control
              type='text'
              name="lastNameKana"
              value={user.lastNameKana}
              readOnly
            />
          </Form.Group>

          <Form.Group as={Col} md="6" controlId='firstNameKana'>
            <Form.Label>メイ</Form.Label>
            <Form.Control
              type='text'
              name="firstNameKana"
              readOnly
              value={user.firstNameKana}
            />
          </Form.Group>
        </Row>

        <Form.Group controlId='phoneNumber'>
          <Form.Label>電話番号</Form.Label>
          <Form.Control
            type='text'
            name="phoneNumber"
            value={user.phoneNumber}
            readOnly
          />
        </Form.Group>

        <Form.Group controlId='email'>
          <Form.Label>メールアドレス</Form.Label>
          <Form.Control
            type='text'
            name="email"
            value={user.email}
            readOnly
          />
        </Form.Group>

        <Form.Group controlId='prefectureId'>
          <Form.Label>お住いの都道府県</Form.Label>
          <Form.Control
            type='text'
            name='prefecture'
            value={prefectures.filter(prefecture => (prefecture.id == user.prefecture.id))[0]?.name || ''}
            readOnly
          >
          </Form.Control>
        </Form.Group>

        <Form.Group controlId='gender'>
          <Form.Label>性別</Form.Label>
          <div className={``}>
            <Form.Check
              inline
              id='man'
              type={'radio'}
              name='gender'
              label='男性'
              checked={formData.gender === 'man'}
              onChange={_e => onChange('man', 'gender')}
            />
            <Form.Check
              inline
              id='woman'
              type={'radio'}
              name='gender'
              label='女性'
              checked={formData.gender === 'woman'}
              onChange={_e => onChange('woman', 'gender')}
            />
            <Form.Check
              inline
              id='other'
              type={'radio'}
              name='gender'
              label='その他'
              checked={formData.gender === 'other'}
              onChange={_e => onChange('other', 'gender')}
            />
          </div>
        </Form.Group>

        <Form.Group controlId='specialityClassifications'>
          <Form.Label>文理</Form.Label>
          <div>
            <Form.Check
              inline
              type={'radio'}
              name='specialityClassifications'
              label='文系'
              checked={formData.specialityClassification === 'arts'}
              onChange={_e => onChange('arts', 'specialityClassification')}
            />
            <Form.Check
              inline
              type={'radio'}
              name='specialityClassifications'
              label='理系'
              checked={formData.specialityClassification === 'sciences'}
              onChange={_e => onChange('sciences', 'specialityClassification')}
            />
            <Form.Check
              inline
              type={'radio'}
              name='specialityClassifications'
              label='その他'
              checked={formData.specialityClassification === 'others'}
              onChange={_e => onChange('others', 'specialityClassification')}
            />
          </div>
        </Form.Group>

        <Form.Group controlId='degree'>
          <Form.Label>最終学歴</Form.Label>
          <Form.Control
            as="select"
            custom
            onChange={e => onChangeKey(searchKey, e.target.value)}
            value={degree}
          >
            <option></option>
            <option value='undergraduate'>大学卒業見込み</option>
            <option value='master'>大学院(修士)卒業見込み</option>
            <option value='doctor'>大学院(博士)卒業見込み</option>
            <option value='junior_college'>短大卒業見込み</option>
            <option value='vocational_school'>専門学校卒業見込み</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId='searchKey'>
          <Form.Label>学校検索</Form.Label>
          <Form.Control
            as="select"
            custom
            value={searchKey}
            onChange={e => onChangeKey(e.target.value, degree)}
          >
            <option></option>
            <option value="あ">あ</option>
            <option value="い">い</option>
            <option value="う">う</option>
            <option value="え">え</option>
            <option value="お">お</option>
            <option value="か">か</option>
            <option value="き">き</option>
            <option value="く">く</option>
            <option value="け">け</option>
            <option value="こ">こ</option>
            <option value="さ">さ</option>
            <option value="し">し</option>
            <option value="す">す</option>
            <option value="せ">せ</option>
            <option value="そ">そ</option>
            <option value="た">た</option>
            <option value="ち">ち</option>
            <option value="つ">つ</option>
            <option value="て">て</option>
            <option value="と">と</option>
            <option value="な">な</option>
            <option value="に">に</option>
            <option value="の">の</option>
            <option value="は">は</option>
            <option value="ひ">ひ</option>
            <option value="ふ">ふ</option>
            <option value="へ">へ</option>
            <option value="ほ">ほ</option>
            <option value="ま">ま</option>
            <option value="み">み</option>
            <option value="む">む</option>
            <option value="め">め</option>
            <option value="も">も</option>
            <option value="や">や</option>
            <option value="よ">よ</option>
            <option value="ら">ら</option>
            <option value="り">り</option>
            <option value="る">る</option>
            <option value="れ">れ</option>
            <option value="わ">わ</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId='name'>
          <Form.Label>学校名</Form.Label>
          <Form.Control
            as="select"
            custom
            onChange={e => { onChange(e.target.value, 'universityId') }}
            value={formData.universityId}
          >
            <option></option>
            {universityOptions.map((universityOption) =>
              <option
                key={`schoolName-${universityOption.id}`}
                value={universityOption.value}
              >
                {universityOption.label}
              </option>
            )}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId='faculty'>
          <Form.Label>学部名</Form.Label>
          <Form.Control
            as="select"
            custom
            value={formData.universityFacultyId}
            onChange={e => { onChange(e.target.value, 'universityFacultyId') }}
          >
            <option></option>
            {universityFacultyOptions.map((facultyOption) =>
              <option
                key={`schoolFaculty-${facultyOption.id}`}
                value={facultyOption.value}
              >
                {facultyOption.label}
              </option>
            )}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId='department'>
          <Form.Label>学科名</Form.Label>
          <Form.Control
            as="select"
            custom
            value={formData.universityDepartmentId}
            onChange={e => { onChange(e.target.value, 'universityDepartmentId') }}
          >
            <option></option>
            {universityDepartmentOptions.map((departmentOptions) =>
              <option
                key={`schoolDepartment-${departmentOptions.id}`}
                value={departmentOptions.value}
              >
                {departmentOptions.label}
              </option>
            )}
          </Form.Control>
        </Form.Group>

        <Form.Group>
          <Form.Label>入学年</Form.Label>
          <DateTime
            format='YYYY/MM/DD'
            onChange={value => onChange(value, "admissionOn")}
            value={formData.admissionOn || ''}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>卒業年</Form.Label>
          <DateTime
            format='YYYY/MM/DD'
            onChange={value => onChange(value, "graduateOn")}
            value={formData.graduateOn}
          />
        </Form.Group>

        <Form.Group controlId='jobHuntingStatus'>
          <Form.Label>就活ステータス</Form.Label>
          <Form.Control
            as="select"
            custom
            value={formData.jobHuntingStatus}
            onChange={e => { onChange(e.target.value, 'jobHuntingStatus') }}
          >
            <option value='active'>就活中</option>
            <option value='inactive'>就活終了</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId='isPhoneAvailable'>
          <Form.Check
            key={'is-phone-available'}
            inline
            id={'isPhoneAvailable'}
            type={'checkbox'}
            name='isPhoneAvailable'
            label={'電話停止'}
            checked={!formData.isPhoneAvailable}
            onChange={e => onChange(!e.target.checked, 'isPhoneAvailable')}
          />
        </Form.Group>

        <Form.Group controlId='isEmailAvailable'>
          <Form.Check
            key={'is-email-available'}
            inline
            id={'isEmailAvailable'}
            type={'checkbox'}
            name='isEmailAvailable'
            label={'メール配信停止'}
            checked={!formData.isEmailAvailable}
            onChange={e => onChange(!e.target.checked, 'isEmailAvailable')}
          />
        </Form.Group>

        <Form.Group>
          <Button type="button" className='mr-2' variant="outline-secondary" onClick={onClose}>キャンセル</Button>
          <Button type="button" variant='outline-success' onClick={handleSubmit}>保存</Button>
        </Form.Group>
      </Form>
    </Panel>
  )
}

export default UserUpdateForm;
